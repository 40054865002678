@use "breakpoints";
@use "buttons";
@use "colors";
@use "layout";
@use "typography";

.page-hero {
  block-size: var(--mobile-page-hero-block-size);
  display: grid;
  grid-template-columns: 1fr;
  overflow: hidden;
  position: relative;
  user-select: none;

  @media (width >= breakpoints.$md) {
    aspect-ratio: 8 / 5;
    block-size: 100%;
  }
}

.background-media {
  grid-column: 1 / span 1;
  grid-row-end: span 1;
  overflow: hidden;
  position: relative;

  @media (width >= breakpoints.$md) {
    aspect-ratio: 8 / 5;
  }
}

.content {
  @include layout.module-content;

  display: grid;
  grid-column: 1 / span 1;
  grid-row-end: span 1;
  grid-template: repeat(12, minmax(0, 1fr)) / repeat(12, minmax(0, 1fr));
  min-block-size: 100%;
  padding-block: 2.4rem;
  pointer-events: none;
  z-index: 10;

  @media (width >= breakpoints.$md) {
    aspect-ratio: 8 / 5;
  }
}

.content-block {
  display: flex;
  flex-direction: column;
  grid-column: 1 / span 12;
  grid-row: 1 / span 12;
  justify-content: center;
}

.headline {
  margin-block-end: 0;
  z-index: 2;

  &:has(+ *) {
    margin-block-end: 4rem;
  }

  @media (width >= breakpoints.$md) {
    white-space: pre-wrap;
  }
}

.subheading {
  @include typography.subhead-regular;

  margin-block-end: 0;
  z-index: 2;

  &:has(+ *) {
    margin-block-end: 4rem;
  }

  @media (width >= breakpoints.$md) {
    white-space: pre-wrap;
  }
}

.cta {
  pointer-events: auto;
  z-index: 2;
}

.scroll-to-next-section {
  background-color: transparent;
  border-radius: 100px;
  inset: auto 2.4rem 2.4rem auto;
  max-inline-size: 43px;
  padding: 0;
  pointer-events: auto;
  position: absolute;
  user-select: none;
  z-index: 2;

  @media (width >= breakpoints.$md) {
    inset: auto auto 2.4rem;
  }
}

/*********************************************************************
 * Headline placement in relation to background image
 */

// has interaction with data-compact="true"
.page-hero[data-headline-placement="overlay"] {
  grid-template-rows: 1fr;

  .background-media {
    grid-row-start: 1;
  }

  .content {
    grid-row-start: 1;
  }
}

.page-hero[data-headline-placement="block-start"],
.page-hero[data-headline-placement="block-end"] {
  grid-template-rows: auto 1fr;

  @media (width >= breakpoints.$md) {
    aspect-ratio: unset;

    .content {
      aspect-ratio: unset;
      padding-block: 4.8rem;
    }
  }

  @media (width >= breakpoints.$lg) {
    .content {
      padding-block: 7.2rem;
    }
  }

  @media (width >= breakpoints.$xl) {
    .content {
      padding-block: 9.6rem;
    }
  }
}

.page-hero[data-headline-placement="block-start"] {
  .background-media {
    grid-row-start: 2;
  }

  .content {
    grid-row-start: 1;
  }
}

.page-hero[data-headline-placement="block-end"] {
  .background-media {
    grid-row-start: 1;
  }

  .content {
    grid-row-start: 2;
  }
}

/*********************************************************************
 * Constrains vertical height to text content size
 */

.page-hero[data-compact="true"] {
  block-size: auto;

  .content {
    aspect-ratio: initial;
    min-block-size: auto;
  }

  @media (width >= breakpoints.$md) {
    aspect-ratio: initial;

    .content {
      aspect-ratio: initial;
    }
  }
}

.page-hero[data-compact="true"][data-headline-placement="overlay"] {
  .background-media {
    aspect-ratio: initial;
    inline-size: 100%;
  }

  .content {
    padding-block: 4rem;
  }

  @media (width >= breakpoints.$md) {
    .background-media {
      aspect-ratio: initial;
    }
  }
}

/*********************************************************************
 * Headline text size
 */

.page-hero[data-headline-size="xl"] .headline {
  @include typography.xl-headline;
}

.page-hero[data-headline-size="large"] .headline {
  @include typography.large-headline;
}

.page-hero[data-headline-size="medium"] .headline {
  @include typography.medium-headline;
}

.page-hero[data-headline-size="small"] .headline {
  @include typography.small-headline;
}

/*********************************************************************
 * Background shade (overlays media)
 */

.page-hero[data-background-shade="true"] {
  .background-media::after {
    background-color: rgba(0, 0, 0, 50%);
    content: "";
    inset: 0;
    position: absolute;
    z-index: 1;
  }
}

/*********************************************************************
 * Text alignment
 */

.page-hero[data-text-align="start"] .content-block {
  align-items: start;
}

.page-hero[data-text-align="center"] .content-block {
  align-items: center;
}

.page-hero[data-text-align="end"] .content-block {
  align-items: end;
}

/*********************************************************************
 * Vertical start point and width
 */

.page-hero[data-layout-block="start"] .content-block {
  grid-row: 1 / span 8;
}

.page-hero[data-layout-block="center"] .content-block {
  grid-row: 3 / span 8;
}

.page-hero[data-layout-block="end"] .content-block {
  grid-row: 5 / span 8;
}

.page-hero[data-layout-block="half-start"] .content-block {
  grid-row: 2 / span 5;
  padding-block: 0;
}

.page-hero[data-layout-block="half-end"] .content-block {
  grid-row: 6 / span 5;
  padding-block: 0;
}

/*********************************************************************
 * Horizontal start point and width
 */

.page-hero[data-layout-inline="start"] .content-block {
  grid-column: 1 / span 8;
}

.page-hero[data-layout-inline="center"] .content-block {
  grid-column: 3 / span 8;
}

.page-hero[data-layout-inline="end"] .content-block {
  grid-column: 5 / span 8;
}

/*********************************************************************
 * Vertical spacing between text elements
 */

.page-hero[data-layout-spacing="start"] .content-block {
  justify-content: start;
}

.page-hero[data-layout-spacing="center"] .content-block {
  justify-content: center;
}

.page-hero[data-layout-spacing="end"] .content-block {
  justify-content: end;
}

.page-hero[data-layout-spacing="loose"] .content-block {
  justify-content: space-evenly;
}

.page-hero[data-layout-spacing="wide"] .content-block {
  justify-content: space-between;
}

/*********************************************************************
 * Overall theme color
 */

.page-hero[data-theme="light"] {
  background-color: colors.$white;
  color: colors.$black;

  .cta {
    @include buttons.button(
      buttons.$theme-primary-dark,
      buttons.$width-primary
    );
  }

  .scroll-to-next-section {
    border: 1px solid colors.$off-black;

    svg {
      color: colors.$off-black;
    }
  }
}

.page-hero[data-theme="dark"] {
  background-color: colors.$black;
  color: colors.$white;

  .cta {
    @include buttons.button(
      buttons.$theme-primary-light,
      buttons.$width-primary
    );
  }

  .scroll-to-next-section {
    border: 1px solid colors.$light-gray;

    svg {
      color: colors.$light-gray;
    }
  }
}

.page-hero[data-theme="light"] .scroll-to-next-section:hover {
  border-color: colors.$black;

  svg {
    color: colors.$black;
  }
}

.page-hero[data-theme="dark"] .scroll-to-next-section:hover {
  border-color: colors.$white;

  svg {
    color: colors.$white;
  }
}

/*********************************************************************
 * Text shadow
 */

.page-hero[data-theme="light"][data-text-shadow="light"] {
  .headline,
  .subheading {
    text-shadow: 1px 1px 2px rgba(colors.$white, 0.25);
  }
}

.page-hero[data-theme="light"][data-text-shadow="medium"] {
  .headline,
  .subheading {
    text-shadow: 1px 1px 2px rgba(colors.$white, 0.5);
  }
}

.page-hero[data-theme="light"][data-text-shadow="heavy"] {
  .headline,
  .subheading {
    text-shadow: 1px 1px 2px rgba(colors.$white, 0.75);
  }
}

.page-hero[data-theme="light"][data-text-shadow="full"] {
  .headline,
  .subheading {
    text-shadow: 1px 1px 2px rgba(colors.$white, 1);
  }
}

.page-hero[data-theme="dark"][data-text-shadow="light"] {
  .headline,
  .subheading {
    text-shadow: 1px 1px 2px rgba(colors.$black, 0.25);
  }
}

.page-hero[data-theme="dark"][data-text-shadow="medium"] {
  .headline,
  .subheading {
    text-shadow: 1px 1px 2px rgba(colors.$black, 0.5);
  }
}

.page-hero[data-theme="dark"][data-text-shadow="heavy"] {
  .headline,
  .subheading {
    text-shadow: 1px 1px 2px rgba(colors.$black, 0.75);
  }
}

.page-hero[data-theme="dark"][data-text-shadow="full"] {
  .headline,
  .subheading {
    text-shadow: 1px 1px 2px rgba(colors.$black, 1);
  }
}
